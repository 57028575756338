import {FormEvent, RefObject, useCallback, useRef, useState} from 'react';


interface SignInFormLogicReturnType {
    missingRequiredFields: boolean;
    emailFieldRef: RefObject<HTMLInputElement>;
    passwordFieldRef: RefObject<HTMLInputElement>;
    handleSubmit: (e: FormEvent) => void;
}


export function useSignInFormLogic(
    submitForm: (email: string, password: string) => void
): SignInFormLogicReturnType {
    const [missingRequiredFields, setMissingRequiredFields] = useState(false);
    const emailFieldRef = useRef<HTMLInputElement>(null);
    const passwordFieldRef = useRef<HTMLInputElement>(null);

    const handleSubmit = useCallback((e: FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        if (!emailFieldRef.current || !passwordFieldRef.current) {
            return;
        }

        const email = emailFieldRef.current.value;
        const password = passwordFieldRef.current.value;

        if (!email || !password) {
            setMissingRequiredFields(true);
            return;
        }

        setMissingRequiredFields(false);
        submitForm(email, password);
    }, [submitForm]);

    return {
        missingRequiredFields,
        emailFieldRef,
        passwordFieldRef,
        handleSubmit
    };
}
