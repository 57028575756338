import styled from 'styled-components';
import React, {ReactNode} from 'react';
import {LandingPageLayoutHeader} from './components/LandingPageLayoutHeader';
import {greyColorTokens} from "../../tokens/color";
import {deviceBreakPointTokens} from "../../tokens/deviceBreakpoints";
import {P2} from "../../primitives/textStyles/Paragraphs";

const ALVA_WEBPAGE_LINK = 'https://www.alvalabs.io';

interface LandingPageLayoutProps {
    children?: ReactNode;
    backgroundImage?: string;
    withLogoInHeader: boolean;
    withLanguageSelector: boolean;
}

export function LandingPageLayout(
    {children, backgroundImage, withLogoInHeader, withLanguageSelector}: LandingPageLayoutProps) {
    return (
        <Wrapper backgroundImage={backgroundImage}>
            <LandingPageLayoutHeader
                isWhite={!backgroundImage}
                withLogo={withLogoInHeader}
                withLanguageSelector={withLanguageSelector}
            />
            <Body>
                {children}
            </Body>
            {backgroundImage ? (
                <Footer>
                    <a
                        href={ALVA_WEBPAGE_LINK}
                        rel='noreferrer noopener'
                        target={'_blank'}
                        style={{color: greyColorTokens.white000()}}
                    >
                        alvalabs.io
                    </a>
                </Footer>
            ) : null}
        </Wrapper>
    );
}

LandingPageLayout.defaultProps = {
    withLogoInHeader: true,
    withLanguageSelector: true
};

const Wrapper = styled.div<{ backgroundImage?: string; children: ReactNode; }>`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  ${({backgroundImage}) => backgroundImage ? `
    background: url(${backgroundImage}) center center no-repeat;
    background-size: cover;
  ` : ''}
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    background: none;
  }
`;

const Body = styled.div`
  flex: 1 1;
`;

const Footer = styled(P2)`
  color: ${greyColorTokens.white000()};
  flex: 0 0;
  &:hover {
    text-decoration: underline;
  }
`;
