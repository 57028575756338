import {useHistory} from 'react-router-dom';
import {useCallback} from "react";
import {AuthorizeParameters} from "../../../models/AuthorizeParameters";
import {formatSignInParametersAsQueryString} from "../utils";

export function useRedirectToApproval(signInParameters: AuthorizeParameters): () => void {
    const history = useHistory();
    return useCallback(() => {
        history.push(`/approve?${formatSignInParametersAsQueryString(signInParameters)}`);
    }, [history, signInParameters]);
}