import React, {ReactNode, useCallback, useMemo} from 'react';
import {useAuthorizeParameters} from "../../hooks/useAuthorizeParameters";
import {useRedirectToApproval} from "./hooks/useRedirectToApproval";
import {useSignIn} from "../../api/useSignIn";
import {borderRadiusTokens} from "../../componentLibrary/tokens/borderRadius";
import {deviceBreakPointTokens} from "../../componentLibrary/tokens/deviceBreakpoints";
import {greyColorTokens} from "../../componentLibrary/tokens/color";
import {DefaultTextStyleCss} from "../../componentLibrary/primitives/textStyles/DefaultTextStyle";
import styled from "styled-components";
import {LandingPageLayout} from "../../componentLibrary/blocks/LandingPageLayout";
import {FormattedMessage} from "react-intl";
import loginPageBackground from "../assets/background.png";
import {ErrorPage} from "../ErrorPage";
import messages from "./messages";
import {SignInForm} from "./components/SignInForm";
import {useClientForRequest} from "../../api/useClientForRequest";


export function SignIn() {
    const [doSignIn, {loading: signingIn, error}] = useSignIn();
    const signInParameters = useAuthorizeParameters();
    const redirectToApproval = useRedirectToApproval(signInParameters);
    const {client, loading: loadingClient} = useClientForRequest(signInParameters.rid);

    const titleMessage = useMemo(() => {
        if (loadingClient) {
            return null;
        }

        if (!client) {
            return <FormattedMessage {...messages.genericTitle}/>;
        }

        return <FormattedMessage {...messages.clientTitle} values={{clientName: client.name}}/>;
    }, [loadingClient, client]);

    const onSubmit = useCallback((email: string, password: string) => {
        doSignIn(email, password)
            .then(() => {
                console.log('redirecting');
                redirectToApproval()
            })
            .catch(error => {
                console.log(error, error.code, error.message);
            });
    }, [doSignIn, redirectToApproval]);

    if (signInParameters.flow !== 'code') {
        return <ErrorPage title={'Unsupported flow'}/>;
    }

    return (
        <LandingPageLayout backgroundImage={loginPageBackground}>
            <OuterWrapper>
                <InnerWrapper>
                    <ClientWrapper isLoading={loadingClient}>
                        {client && client.logo ? (
                            <ClientLogo logoUrl={client.logo}/>
                        ) : null}
                        <TextWrapper hasLogo={client ? !!client.logo : false}>
                            {titleMessage}
                        </TextWrapper>
                    </ClientWrapper>
                    <LoginFormWrapper>
                        <SignInForm
                            submitForm={onSubmit}
                            loading={signingIn}
                            errorMessage={error ? error.code : null}
                        />
                    </LoginFormWrapper>
                </InnerWrapper>
            </OuterWrapper>
        </LandingPageLayout>
    );
}

const OuterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
    max-width: 964px;
  }
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
  }
`;

const ClientWrapper = styled.div<{isLoading: boolean; children: ReactNode;}>`
  display: flex;
  flex-direction: column;
  max-width: 440px;
  margin-right: 84px;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    margin-top: 48px;
    margin-right: 0;
  }
  ${({isLoading}) => isLoading ? `
    width: 440px;
    height: 100%;
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
      width: 100%;
    }
  ` : ''}
` ;

const TextWrapper = styled.div<{hasLogo: boolean; children: ReactNode;}>`
  ${DefaultTextStyleCss}
  display: flex;
  align-items: flex-start;
  padding-top: ${({hasLogo}) => hasLogo ? 0 : '60px'};
  color: ${greyColorTokens.white000()};
  font-size: 60px;
  line-height: 58px;
  letter-spacing: -3px;
  word-wrap: break-word;
  flex: 1;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    margin-top: ${({hasLogo}) => hasLogo ? 0 : '48px'};
    color: ${greyColorTokens.grey000()};
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 32px;
    padding-top: 0;
    margin-right: 0;
  }
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    width: 100%;
  }
`;

const ClientLogo = styled.div<{logoUrl: string;}>`
  width: 100%;
  height: 28px;
  background: url(${({logoUrl}) => logoUrl}) no-repeat;
  margin-bottom: 32px;
  background-size: contain;
`;

const LoginFormWrapper = styled.div`
  background-color: ${greyColorTokens.white000()};
  max-width: 440px;
  height: 100%;
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    width: 100%;
  }
  @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
    width: 440px;
  }
  border-radius: ${borderRadiusTokens.small};
`;
