import {hexToRGBA} from "../../utils/hexToRGBA";

export const greyColorTokens = {
    white000: (opacity = 1) => hexToRGBA('#FFFFFF', opacity),
    white100: (opacity = 1) => hexToRGBA('#F4F4F4', opacity),
    grey000: (opacity = 1) => hexToRGBA('#282828', opacity),
    grey200: (opacity = 1) => hexToRGBA('#737373', opacity),
    grey400: (opacity = 1) => hexToRGBA('#9E9E9E', opacity),
    grey500: (opacity = 1) => hexToRGBA('#B4B4B4', opacity),
    grey600: (opacity = 1) => hexToRGBA('#C4C4C4', opacity),
    grey800: (opacity = 1) => hexToRGBA('#DFDFDF', opacity),
    grey900: (opacity = 1) => hexToRGBA('#F0F0F0', opacity)
};

export const primaryColorTokens = {
    yellow: (opacity = 1) => hexToRGBA('#F6C243', opacity)
};

export const tertiaryColorTokens = {
    yellow000: (opacity = 1) => hexToRGBA('#FFEFC5', opacity),
    yellow300: (opacity = 1) => hexToRGBA('#EE892B', opacity),
    blue100: (opacity = 1) => hexToRGBA('#B0C7FF', opacity),
    blue200: (opacity = 1) => hexToRGBA('#4330FC', opacity),
    blue300: (opacity = 1) => hexToRGBA('#190D7B', opacity),
    red000: (opacity = 1) => hexToRGBA('#F9D6CE', opacity),
    red300: (opacity = 1) => hexToRGBA('#C10D30', opacity)
};
