import {useCallback, useEffect, useMemo, useState} from "react";
import {Organization} from "../../models/Organization";
import {useOrganizations} from "../../api/useOrganizations";
import {useRedirectToClient} from "./hooks/useRedirectToClient";
import {useCompleteAuthorization} from "../../api/useCompleteAuthorization";
import {AuthorizeParameters} from "../../models/AuthorizeParameters";
import {ApiError} from "../../models/ApiError";


interface useApproveAuthorizationLogicReturnType {
    loadingOrganizations: boolean;
    loading: boolean;
    organizationsError?: ApiError;
    error?: ApiError;
    selectedOrganization: Organization | null;
    setSelectedOrganization: (item: Organization) => void;
    approveAuthorization: () => void;
    manageableOrganizations: Array<Organization>;
    organizations: Array<Organization>;
}


export function useApproveAuthorizationLogic(parameters: AuthorizeParameters): useApproveAuthorizationLogicReturnType {
    const {organizations, error: organizationsError, loading: loadingOrganizations} = useOrganizations();
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const redirectToClient = useRedirectToClient();
    const [completeAuthorization, {error, loading}] = useCompleteAuthorization(parameters.rid);

    const approveAuthorization = useCallback(() => {
        if (!selectedOrganization) {
            return;
        }
        completeAuthorization(selectedOrganization.id)
            .then(completedAuthorization => {
                redirectToClient(completedAuthorization)
            })
            .catch(console.error);
    }, [completeAuthorization, redirectToClient, selectedOrganization]);

    const manageableOrganizations = useMemo(() => {
        if (loadingOrganizations) {
            return [];
        }

        return organizations.filter(organization => organization.userIsAllowedToConfigureIntegration);
    }, [loadingOrganizations, organizations]);

    useEffect(() => {
        if (manageableOrganizations.length === 1) {
            setSelectedOrganization(manageableOrganizations[0]);
        }
    }, [manageableOrganizations]);

    return {
        loadingOrganizations,
        loading,
        organizationsError,
        error,
        selectedOrganization,
        setSelectedOrganization,
        approveAuthorization,
        manageableOrganizations,
        organizations
    };
}
