import React, {ChangeEvent, Fragment, MutableRefObject, ReactNode, useCallback, useMemo, useState} from 'react';
import styled, {FlattenSimpleInterpolation} from 'styled-components';
import {useIntl} from 'react-intl';
import messages from './messages';
import {greyColorTokens, tertiaryColorTokens} from "../../tokens/color";
import {fontSizeTokens} from "../../tokens/fontSize";
import {deviceBreakPointTokens} from "../../tokens/deviceBreakpoints";
import {TextFieldErrorMessage} from "./TextFieldErrorMessage";
import {TextFieldHelpText} from "./TextFieldHelpText";
import {TextFieldLabel} from "./TextFieldLabel";
import {v4 as uuid} from 'uuid';

interface TextFieldProps {
    className?: string;
    type: string;
    defaultValue?: string | number | Array<string>;
    value?: string | number | Array<string>;
    label?: ReactNode;
    placeholder?: ReactNode;
    helpText?: ReactNode;
    errorMessage?: ReactNode;
    innerRef: MutableRefObject<any>;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => any;
    onFocus?: () => any;
    disabled?: boolean;
    isRequired?: boolean;
    hasError?: boolean;
    autoFocus?: boolean;
    fullWidth?: boolean;
    additionalCss?: FlattenSimpleInterpolation | string;
    [x: string]: any;
}


export function TextField(props: TextFieldProps) {
    const [hasText, setHasText] = useState(!!props.value || !!props.defaultValue);
    const id = useMemo(() => uuid(), []);
    const intl = useIntl();

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value) {
            setHasText(true);
        }
        else {
            setHasText(false);
        }
        if (props.onChange) {
            props.onChange(e);
        }
    }, [props]);

    /* eslint-disable no-unused-vars */
    const {
        onChange,
        className,
        innerRef,
        hasError,
        additionalCss,
        placeholder,
        ...otherProps
    } = props;

    return (
        <Fragment>
            {props.label ? (
                <label htmlFor={id}>
                    <TextFieldLabel isRequired={props.isRequired}>
                        {props.label}
                    </TextFieldLabel>
                </label>
            ) : null}
            <Input
                id={id}
                className={`${className} ${hasError ? 'error' : ''}`}
                hasText={hasText}
                onChange={handleChange}
                ref={innerRef}
                additionalCss={additionalCss}
                placeholder={placeholder ? placeholder : intl.formatMessage(messages.defaultPlaceholder)}
                {...otherProps}
            />
            {props.helpText && !(props.hasError && props.errorMessage) ? (
                <TextFieldHelpText>
                    {props.helpText}
                </TextFieldHelpText>
            ) : null}

            {(props.errorMessage) ? (
                <ErrorMessageWrapper>
                    {props.hasError ? (
                        <TextFieldErrorMessage>
                            {props.errorMessage}
                        </TextFieldErrorMessage>
                    ) : null}
                </ErrorMessageWrapper>
            ) : null}
        </Fragment>
    );
}

TextField.defaultProps = {
    onChange: () => undefined,
    label: null,
    helpText: null,
    errorMessage: null,
    additionalCss: ''
};

interface InputProps {
    id: string;
    className: string;
    fullWidth?: boolean;
    hasText: boolean;
    additionalCss?: FlattenSimpleInterpolation | string;
    onChange: (e: ChangeEvent<any>) => void;
    ref: MutableRefObject<HTMLInputElement>;
    placeholder: any;
}

const Input = styled.input<InputProps>`
  &[type="number"],
  &[type="email"],
  &[type="password"],
  &[type="text"] {
      font-family: 'Euclid Circular A', sans-serif;
      font-size: ${fontSizeTokens.smaller};
      
      height: 40px;
      width: ${({fullWidth}) => fullWidth ? '100%' : '280px'};
      padding: 0 16px;
      box-sizing: border-box;

      background-color: ${greyColorTokens.white000()};
      color: ${greyColorTokens.grey000()};
      border: 1px solid ${greyColorTokens.grey800()};
      border-radius: 40px;
     
      &::placeholder {
        color: ${greyColorTokens.grey400()};
      }
      
      &:hover {
        border: 1px solid ${greyColorTokens.grey400()};
      }

      &:focus {
         border: 1px solid ${tertiaryColorTokens.blue200()};
         outline: none;
      }

      &.error {
         border: 1px solid ${tertiaryColorTokens.red300()};
      }
      
     &:disabled {
        cursor: not-allowed;
        background-color: ${greyColorTokens.white100()};
        border: 1px solid ${greyColorTokens.grey800()};
       ${({hasText}) => hasText ? `
          color: ${greyColorTokens.grey500()};
        ` : `
          color: ${greyColorTokens.grey800()};
          &::placeholder {
            color: ${greyColorTokens.grey800()};
          }
        `};
        &:hover {
          border: 1px solid ${greyColorTokens.grey800()};
        }
      }
      
     ${({additionalCss}) => additionalCss}
     @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
      font-size: 16px;  // Needed to prevent weird zoom behaviour when focusing input
     }
  }
`;

const ErrorMessageWrapper = styled.div`
  height: 24px;
  display: flex;
  align-items: center;
`;
