import styled, {css} from 'styled-components';
import {fontSizeTokens} from "../../tokens/fontSize";
import {borderRadiusTokens} from "../../tokens/borderRadius";
import {greyColorTokens} from "../../tokens/color";
import {ReactNode} from "react";
import {fontWeightTokens} from "../../tokens/fontWeight";


export interface ButtonBaseProps {
    isLoading?: boolean;
    lookDisabled?: boolean;
    minWidth?: number;
    isFullWidth?: boolean;
    isSmall?: boolean;
    children: ReactNode;
    type?: "button" | "submit" | "reset";
    disabled?: boolean;
    onClick?: (e?: any) => void;
}

export const ButtonBase = styled.button<ButtonBaseProps>`
  font-family: 'Euclid Circular A', sans-serif;
  font-style: normal;
  font-weight: ${fontWeightTokens.regular};
  font-size: ${fontSizeTokens.smaller};
  line-height: 20px;
  color: ${greyColorTokens.grey000()};
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  width: ${props => props.isFullWidth ? '100%' : 'auto'};
  border-radius: ${borderRadiusTokens.small};
  border: 1px solid ${greyColorTokens.grey400()}; // Needs to be here, to make all buttons the same size
  
  ${({minWidth}) => minWidth ? `
    min-width: ${minWidth}px;
  ` : ''}
  
  ${({isSmall}) => isSmall ? `
    padding: 5px 16px;
  ` : `
    padding: 9px 16px;
  `}
  
  ${({lookDisabled}) => lookDisabled ? (css`
    cursor: not-allowed;
    &:hover {
       cursor: not-allowed;
    }
  `) : ''} 
  
  ${({isLoading}) => isLoading ? (css`
    pointer-events: none; 
    color: transparent!important;
    
    &:after {
      animation: spinAround .5s infinite linear;
      border: 2px solid ${greyColorTokens.grey400()};
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
      position: absolute!important; 
    }
  `) : ''}
      
  &:focus {
    outline: none;
  }
      
  &:disabled:hover,
  &:disabled {
    cursor: not-allowed;
  }
`;

ButtonBase.defaultProps = {
    isLoading: false,
    lookDisabled: false,
    isFullWidth: false,
    isSmall: false
};

export const PlainButtonBase = styled.button`
  background: none;
  border: none;
  font-family: 'Euclid Circular A', sans-serif;
  font-style: normal;
  font-weight: ${fontWeightTokens.regular};
  font-size: ${fontSizeTokens.smaller};
  line-height: 20px;
  color: ${greyColorTokens.grey000()};
  
  display: flex;
  align-items: center;
  cursor: pointer;
  
  :hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  &:disabled:hover,
  &:disabled {
    cursor: not-allowed;
    text-decoration: none;
  }
`;
