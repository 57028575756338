import {useCallback, useEffect} from 'react';

interface ScrollLogicReturnType {
    onScroll: (e: any) => void;
}

export function useScrollLogic(
    highlightedIndex: number | null,
    setHighlightedIndex: (itemsCount: number) => void,
    itemsCount: number,
    onScrollToBottom?: () => void
): ScrollLogicReturnType {
    const onScroll = useCallback((e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setHighlightedIndex(itemsCount - 1);
        }
    }, [setHighlightedIndex, itemsCount]);

    useEffect(() => {
        if (highlightedIndex === itemsCount - 1 && onScrollToBottom) {
            onScrollToBottom();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highlightedIndex, itemsCount]);

    return {onScroll};
}
