import React, {Fragment} from 'react';
import {IntlProvider} from 'react-intl';
import {Helmet} from 'react-helmet';
import {TranslationMessagesType} from "../types";
import {LocaleContext, useCreateLocaleContext} from "./context/LocaleContext";

interface LanguageProviderProps {
    messages: { [locale: string]: TranslationMessagesType };
    children: React.ReactNode;
}

export function LanguageProvider({messages, children}: LanguageProviderProps) {
    const {locale, changeLocale} = useCreateLocaleContext();
    return (
        <LocaleContext.Provider value={{locale, changeLocale}}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <Fragment>
                    <Helmet htmlAttributes={{lang: locale}}/>
                    {React.Children.only(children)}
                </Fragment>
            </IntlProvider>
        </LocaleContext.Provider>
    );
}
