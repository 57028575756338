import React from 'react';
import {FormattedMessage} from "react-intl";
import messages from "./messages";
import {ErrorPage} from "../ErrorPage";

export function NotFound() {
    return (
        <ErrorPage title={<FormattedMessage {...messages.title}/>} withLanguageSelector={true}/>
    );
}
