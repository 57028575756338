import styled from 'styled-components';
import {DefaultTextStyleCss} from "./DefaultTextStyle";
import {fontSizeTokens} from "../../tokens/fontSize";
import {lineHeightTokens} from "../../tokens/lineHeight";
import {TextStyleProps} from "./types";
import {greyColorTokens} from "../../tokens/color";


export const P1 = styled.p<TextStyleProps>`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.small};
    line-height: ${lineHeightTokens.small};
    color: ${({color}) => color};
`;

P1.defaultProps = {
    color: greyColorTokens.grey000()
};

export const P2 = styled.p`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.smaller};
    line-height: ${lineHeightTokens.small};
`;
