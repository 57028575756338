import React, {useCallback} from 'react';
import {matchSorter} from 'match-sorter';
import {useLocaleContext} from "../../../../localization/LanguageProvider/context/LocaleContext";
import {Dropdown} from "../Dropdown";
import {AVAILABLE_LOCALE_NAMES, AVAILABLE_LOCALES} from "../../../../localization/LanguageProvider/constants";
import {FlattenSimpleInterpolation} from "styled-components";

interface LocaleItem {
    id: string;
    name: string;
}

const SEARCHABLE_ITEMS = AVAILABLE_LOCALES.map((locale: string): LocaleItem => {
    return {
        id: locale,
        name: AVAILABLE_LOCALE_NAMES[locale]
    };
});

interface LanguageDropdownProps {
    fullWidth?: boolean;
    inputCss?: FlattenSimpleInterpolation | string;
}

export function LanguageDropdown({fullWidth, inputCss}: LanguageDropdownProps) {
    const {locale, changeLocale} = useLocaleContext();

    const handleSelect = useCallback((selectedLocale?: string) => {
        if (!selectedLocale) {
            return;
        }
        changeLocale(selectedLocale);
    }, [changeLocale]);

    const itemToId = useCallback((localeName: string): string => localeName, []);

    const itemToString = useCallback((localeName: string): string => {
        return AVAILABLE_LOCALE_NAMES[localeName];
    }, []);

    const languageFilter = useCallback((searchString?: string): Array<string> => {
        if (!searchString) {
            return AVAILABLE_LOCALES;
        }

        const matches = matchSorter(SEARCHABLE_ITEMS, searchString, {keys: ['id', 'name']});
        return matches.map(item => item.id);
    }, []);

    return (
        <Dropdown
            fullWidth={fullWidth}
            selectedItem={locale}
            items={AVAILABLE_LOCALES}
            itemToId={itemToId}
            itemToString={itemToString}
            onSelect={handleSelect}
            customFilter={languageFilter}
            inputCss={inputCss}
        />
    );
}

LanguageDropdown.defaultProps = {
    inputCss: ''
};
