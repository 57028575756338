import {useCallback, useEffect, useMemo, useRef} from 'react';
import {AppConfig, getConfig} from "../../config";
import {IntercomSettings} from "./types/IntercomSettings";
import {User} from "../../models/User";
import {getUserName} from "../../utils";
import {useAuthenticatedUserContext} from "../authenticatedUser/AuthenticatedUserContext";

const customWindow = (window as any);

export function Intercom(): null {
    const config = getConfig();
    useWindowIntercom(config);
    const {authenticatedUser} = useAuthenticatedUserContext();
    const settings = useIntercomSettings(config, authenticatedUser);
    const booted = useRef<boolean>(false);

    const bootIntercom = useCallback(() => {
        if (customWindow.Intercom) {
            customWindow.Intercom('boot', settings);
            booted.current = true;
        }
    }, [settings]);

    const updateIntercom = useCallback(() => {
        if (customWindow.Intercom) {
            customWindow.Intercom('update', settings);
        }
    }, [settings]);

    useEffect(() => {
        if (!settings) {
            return;
        }
        if (!booted.current) {
            bootIntercom();
            return;
        }
        updateIntercom();
    }, [bootIntercom, updateIntercom, settings, booted]);

    return null;
}

function useIntercomSettings(config: AppConfig, user: User | null): IntercomSettings {
    return useMemo(() => {
        const settings: IntercomSettings = {
            app_id: config.INTERCOM_APP_ID
        };

        if (user) {
            settings['user_id'] = user.userId;
            settings['user_hash'] = user.intercomVerificationToken;
            settings['name'] = getUserName(user);
            settings['email'] = user.email;
        }

        return settings;
    }, [user, config]);
}

function useWindowIntercom(config: AppConfig): void {
    useEffect(() => {
        if (!customWindow.Intercom) {
            // @ts-ignore
            (function (w: any, d: any, id: string, s: any, x: any) {
                function i() {
                    i.c(arguments);
                }

                // @ts-ignore
                i.q = [];
                i.c = function (args: any) {
                    i.q.push(args);
                };
                w.Intercom = i;
                s = d.createElement('script');
                s.async = 1;
                s.src = 'https://widget.intercom.io/widget/' + id;
                x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            })(window, window.document, config.INTERCOM_APP_ID);
        }
    }, [config.INTERCOM_APP_ID]);
}
