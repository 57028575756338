import React, {ReactNode} from 'react';
import {Popper} from 'react-popper';
import {FormattedMessage} from 'react-intl';
import styled from 'styled-components';
import {useScrollLogic} from './logic';
import {NoDropdownMenuItems} from "../DropdownMenuItems";
import messages from "../messages";
import {greyColorTokens} from "../../../tokens/color";
import {borderRadiusTokens} from "../../../tokens/borderRadius";
import {GetMenuPropsOptions} from "downshift";
import ReactDOM from 'react-dom';

const modifiers = [{
    name: 'preventOverflow',
    enabled: false,
    options: {
        boundary: 'viewport'
    }
}, {
    name: 'flip',
    enabled: false,
    options: {
        boundary: 'viewport',
        rootBoundary: 'viewport'
    }
}, {
    name: 'offset',
    enabled: true,
    options: {
        offset: [0, 4]
    }
}];

interface DropdownMenuProps {
    children: ReactNode;
    noItems: boolean;
    getMenuProps: (props: GetMenuPropsOptions) => any;
    onScrollToBottom?: () => any;
    noItemsMessage: ReactNode;
    width?: number;
    highlightedIndex: number | null;
    setHighlightedIndex: (args: any) => any;
    itemsCount: number;
}

export function DropdownMenu(props: DropdownMenuProps) {
    const {
        children,
        noItems,
        getMenuProps,
        onScrollToBottom,
        highlightedIndex,
        noItemsMessage,
        setHighlightedIndex,
        itemsCount,
        width
    } = props;

    const {onScroll} = useScrollLogic(highlightedIndex, setHighlightedIndex, itemsCount, onScrollToBottom);

    return ReactDOM.createPortal(
        <Popper placement={'bottom-start'} modifiers={modifiers}>
            {({ref, style, placement}) => {
                const menuProps = getMenuProps({
                    onScroll: onScroll,
                    ref: ref,
                    style: Object.assign({}, style, {zIndex: 999, width: `${width}px`})
                });
                return (
                    <DropdownMenuWrapper
                        {...menuProps}
                        data-placement={placement}
                    >
                        {noItems ? (
                            <NoDropdownMenuItems>
                                {noItemsMessage ? noItemsMessage : (
                                    <FormattedMessage {...messages.noItems}/>
                                )}
                            </NoDropdownMenuItems>
                        ) : null}
                        {children}
                    </DropdownMenuWrapper>
                );
            }}
        </Popper>,
        document.body);
}

const DropdownMenuWrapper = styled.div`
    background-color: ${greyColorTokens.white000()};
    border: 1px solid ${greyColorTokens.grey800()};
    box-shadow: 0px 1px 3px rgba(83, 83, 83, 0.2);
    border-radius: ${borderRadiusTokens.small};
    padding: 0;
    max-height: 292px;
    overflow: auto;
`;
