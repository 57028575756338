import styled from 'styled-components';
import disabledDropdownIndicator from './assets/disabledDropdownIndicator.svg';
import dropdownIndicator from './assets/dropdownIndicator.svg';
import React, {useCallback, useMemo} from 'react';


interface DropdownIndicatorProps {
    isDisabled?: boolean;
    menuIsOpen: boolean;
    onClick?: () => void;
}


export function DropdownIndicator({isDisabled, menuIsOpen, onClick}: DropdownIndicatorProps) {
    const dropdownIndicatorIcon = useMemo(() => {
        if (isDisabled) {
            return disabledDropdownIndicator;
        }
        return dropdownIndicator;
    }, [isDisabled]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <DropdownIndicatorWrapper
            imageUrl={dropdownIndicatorIcon}
            menuIsOpen={menuIsOpen}
            onClick={handleClick}
        />
    );
}

interface DropdownIndicatorWrapperProps {
    imageUrl: string;
    menuIsOpen: boolean;
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DropdownIndicatorWrapper = styled.div<DropdownIndicatorWrapperProps>`
  background: ${({imageUrl}) => `transparent url(${imageUrl}) center center no-repeat`};
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({menuIsOpen}) => menuIsOpen ? 'transform: rotate(180deg)' : ''};
  pointer-events: ${({menuIsOpen}) => menuIsOpen ? 'initial' : 'none'};
  margin-right: 8px;
`;
