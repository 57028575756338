import {useCallback, useEffect, useState} from "react";
import {ApiError} from "../models/ApiError";
import {createUrl} from "../config";
import {createUnknownError, parseApiError} from "./utils";

type Client = {
    name: string;
    logo: string;
}

interface useClientForRequestReturnType {
    client: Client | null;
    error?: ApiError;
    loading: boolean;
}

export function useClientForRequest(requestId: string | null): useClientForRequestReturnType {
    const [error, setError] = useState<ApiError | undefined>();
    const [loading, setLoading] = useState(true);
    const [client, setClient] = useState<Client | null>(null);
    const fetchClient = useCallback(() => {
        setLoading(true);
        setError(undefined);
        fetch(createUrl('/v1/api/clients.getByRequestId'), {
            method: 'POST',
            body: JSON.stringify({
                requestId
            }),
            credentials: 'include'
        }).then(response => {
            response.json().then(body => {
                if (response.status === 200) {
                    setClient(parseClient(body))
                } else {
                    setError(parseApiError(body))
                }
                setLoading(false);
            });
        }).catch(reason => {
            setError(createUnknownError(reason));
            setLoading(false);
        });
    }, [requestId]);

    useEffect(() => {
        fetchClient();
    }, [fetchClient]);
    return {client, error, loading}
}

function parseClient(body: any): Client {
    const client = body.data;
    return {
        name: client.name,
        logo: client.logo
    };
}
