import React, {ReactNode, useCallback} from 'react';
import Downshift from 'downshift';
import {DropdownMenu} from '../DropdownMenu';
import {
    useDisplayValueLogic,
    useEqualityLogic,
    useFilteringLogic,
    useMenuLogic,
    useSearchStringLogic,
    useSelectLogic
} from './logic';
import styled, {FlattenSimpleInterpolation} from 'styled-components';
import {FormattedMessage} from 'react-intl';
import messages from './messages';
import {TextFieldLabel} from "../../inputs/TextFieldLabel";
import {TextField} from "../../inputs/TextField";
import {DropdownLoadingState} from "../DropdownLoadingState";
import {ClearDropdownSelection} from "../ClearDropdownSelection";
import {TextFieldHelpText} from "../../inputs/TextFieldHelpText";
import {TextFieldErrorMessage} from "../../inputs/TextFieldErrorMessage";
import {DefaultDropdownMenuItem} from "../DropdownMenuItems";
import {DropdownIndicator} from "../DropdownIndicator";
import {ItemIdReturnType, ItemType} from "./types";
import {Manager, Reference} from "react-popper";


function getInputCursor(isFocused: boolean, isDisabled?: boolean): string {
    if (isDisabled) {
        return 'not-allowed';
    }
    if (isFocused) {
        return 'text';
    }
    return 'pointer';
}

interface DropdownProps {
    items: Array<ItemType>;
    defaultValue?: ItemType;
    selectedItem?: ItemType;
    onSelect?: (item: any) => void;
    isLoading?: boolean;
    label?: ReactNode;
    helpText?: ReactNode;
    hasError?: boolean;
    errorMessage?: ReactNode;
    placeholder?: ReactNode;
    isRequired?: boolean;
    isDisabled?: boolean;
    fullWidth?: boolean;
    onSearchStringChange?: (s?: string) => void;
    searchString?: string;
    itemToString?: (item: any) => string;
    itemToId?: (item: any) => ItemIdReturnType;
    components: {
        Item: any;
        Indicator: any;
    },
    onMenuScrollToBottom?: () => any;
    disableDefaultFilter?: boolean;
    customFilter?: (s?: string) => any;
    isItemDisabled?: (item: ItemType) => boolean;
    noItemsMessage?: ReactNode;
    isClearable?: boolean;
    onBlur?: () => void;
    inputCss?: FlattenSimpleInterpolation | string;
}

export function Dropdown(props: DropdownProps) {
    const {
        Item = DefaultDropdownMenuItem,
        Indicator = DropdownIndicator
    } = props.components;

    const {searchString, handleInputChange, clearSearchString} = useSearchStringLogic(
        props.searchString, props.onSearchStringChange
    );
    const {itemsToShow} = useFilteringLogic(
        props.items, props.disableDefaultFilter, searchString, props.customFilter
    );
    const {isFocused, handleOnFocus, handleOnBlur, blur, inputRef} = useMenuLogic(
        clearSearchString, props.onBlur
    );
    const {handleSelect, selectedItem} = useSelectLogic(
        clearSearchString, props.selectedItem, blur, props.defaultValue, props.onSelect
    );
    const {itemToString, displayValue} = useDisplayValueLogic(isFocused, selectedItem, searchString, props.itemToString);
    const {itemToId} = useEqualityLogic(props.itemToId);

    const stateReducer = useCallback((_, changes) => {
        switch (changes.type) {
            case Downshift.stateChangeTypes.keyDownEscape:
                blur();
                return {isOpen: false};
            default:
                return changes;
        }
    }, [blur]);

    return (
        <Downshift
            itemToString={itemToString}
            onOuterClick={blur}
            onSelect={handleSelect}
            isOpen={isFocused}
            initialHighlightedIndex={0}
            inputValue={displayValue}
            defaultHighlightedIndex={0}
            selectedItem={selectedItem}
            stateReducer={stateReducer}
        >
            {({
                  getRootProps,
                  getMenuProps,
                  getLabelProps,
                  getItemProps,
                  getInputProps,
                  isOpen,
                  setHighlightedIndex,
                  highlightedIndex,
                  clearSelection
              }) => {
                return (
                    <Wrapper fullWidth={props.fullWidth} {...getRootProps()} style={{position: 'relative'}}>
                        {props.label ? (
                            <TextFieldLabel isRequired={props.isRequired} {...getLabelProps()}>
                                {props.label}
                            </TextFieldLabel>
                        ) : null}

                        <Manager>
                            <Reference>
                                {({ref}) => (
                                    <div ref={ref} style={{position: 'relative', width: '100%'}}>
                                        <FormattedMessage {...messages.placeholder}>
                                            {defaultPlaceholder => (
                                                <TextField
                                                    fullWidth={props.fullWidth}
                                                    placeholder={props.placeholder ? props.placeholder : defaultPlaceholder}
                                                    hasError={props.hasError}
                                                    disabled={props.isDisabled}
                                                    {...getInputProps({
                                                        type: 'text',
                                                        innerRef: inputRef,
                                                        onChange: handleInputChange,
                                                        onFocus: handleOnFocus,
                                                        onBlur: handleOnBlur,
                                                        style: {
                                                            paddingRight: '30px',
                                                            cursor: getInputCursor(isFocused, props.isDisabled)
                                                        },
                                                        pointerEvents: props.isLoading ? 'none' : 'auto'
                                                    }) as any}
                                                    additionalCss={props.inputCss}
                                                />
                                            )}
                                        </FormattedMessage>

                                        <RightSideWrapper>
                                            {props.isLoading ? (
                                                <DropdownLoadingState/>
                                            ) : null}
                                            {props.isClearable ? (
                                                <ClearDropdownSelection onClick={clearSelection}/>
                                            ) : null}
                                            <Indicator
                                                menuIsOpen={isOpen}
                                                isDisabled={props.isDisabled}
                                                onClick={blur}
                                            />
                                        </RightSideWrapper>
                                    </div>
                                )}
                            </Reference>
                            {isOpen ? (
                                <DropdownMenu
                                    getMenuProps={getMenuProps}
                                    noItems={itemsToShow.length === 0}
                                    noItemsMessage={props.noItemsMessage}
                                    width={(inputRef.current as any).offsetWidth}
                                    onScrollToBottom={props.onMenuScrollToBottom}
                                    highlightedIndex={highlightedIndex}
                                    setHighlightedIndex={setHighlightedIndex}
                                    itemsCount={props.items.length}
                                >
                                    {itemsToShow.map((item: any, index: number) => (
                                        <Item
                                            key={`menu-item-${itemToId(item)}`}
                                            item={item}
                                            isDisabled={props.isItemDisabled && props.isItemDisabled(item)}
                                            index={index}
                                            itemToString={itemToString}
                                            getItemProps={getItemProps}
                                            isSelected={itemToId(item) === itemToId(selectedItem)}
                                            isHighlighted={index === highlightedIndex}
                                        />
                                    ))}
                                </DropdownMenu>
                            ) : null}
                        </Manager>
                        {props.helpText && !(props.hasError && props.errorMessage) ? (
                            <TextFieldHelpText>
                                {props.helpText}
                            </TextFieldHelpText>
                        ) : null}

                        {props.hasError && props.errorMessage ? (
                            <TextFieldErrorMessage>
                                {props.errorMessage}
                            </TextFieldErrorMessage>
                        ) : null}
                    </Wrapper>
                );
            }}
        </Downshift>
    );
}

Dropdown.defaultProps = {
    defaultValue: null,
    components: {},
    disableDefaultFilter: false,
    customFilter: null,
    inputCss: ''
};

const Wrapper = styled.div<{ fullWidth: boolean; }>`
  width: ${({fullWidth}) => fullWidth ? '100%' : '280px'};
`;

const RightSideWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  align-items: center;
  display: flex;
  pointer-events: none;
`;
