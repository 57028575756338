import {defineMessages} from 'react-intl';

export default defineMessages({
    noItems: {
        id: 'dropdownMenu.noItems'
    },
    noAccess: {
        id: 'organizationMenuItem.noAccess'
    }
});
