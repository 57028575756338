import React, {ReactNode} from "react";
import {LandingPageLayout} from "../../componentLibrary/blocks/LandingPageLayout";
import backgroundImage from "../assets/background.png";
import styled from "styled-components";
import {deviceBreakPointTokens} from "../../componentLibrary/tokens/deviceBreakpoints";
import {DefaultTextStyleCss} from "../../componentLibrary/primitives/textStyles/DefaultTextStyle";
import {greyColorTokens} from "../../componentLibrary/tokens/color";
import {P1} from "../../componentLibrary/primitives/textStyles/Paragraphs";

interface ErrorPageProps {
    title: ReactNode;
    description?: ReactNode;
    withLanguageSelector?: boolean;
}

export function ErrorPage({title, description, withLanguageSelector}: ErrorPageProps) {
    return (
        <LandingPageLayout backgroundImage={backgroundImage} withLanguageSelector={withLanguageSelector}>
            <Wrapper>
                <TextWrapper>
                    <TitleWrapper>
                        {title}
                    </TitleWrapper>
                    {description ? (
                        <Description color={greyColorTokens.white100()}>
                            {description}
                        </Description>
                    ) : null}
                </TextWrapper>
            </Wrapper>
        </LandingPageLayout>
    );
}

ErrorPage.defaultProps = {
    withLanguageSelector: false
};

const Wrapper = styled.div`
  margin-left: 114px;
  margin-top: 100px;
  @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
    max-width: 964px;
  }
  display: flex;
  justify-content: flex-start;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) {
    width: 440px;
  }
`;

const TitleWrapper = styled.div`
  ${DefaultTextStyleCss}
  color: ${greyColorTokens.white000()};
  font-size: 60px;
  line-height: 58px;
  letter-spacing: -3px;  
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    color: ${greyColorTokens.grey000()};
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 32px;
    padding-top: 48px;
  }
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    width: 100%;
  }
  margin-bottom: 20px;
`;

const Description = styled(P1)`
  color: ${greyColorTokens.white100()};
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    color: ${greyColorTokens.grey000()};
  }
`;
