export interface AppConfig {
    BASE_URL: string;
    API_BASE_URL: string;
    ENVIRONMENT: string;
    ALVA_APP_BASE_URL: string;
    INTERCOM_APP_ID: string;
}

const LocalConfig: AppConfig = {
    BASE_URL: 'http://localhost:3003',
    API_BASE_URL: 'http://localhost:1354',
    ENVIRONMENT: 'local',
    ALVA_APP_BASE_URL: 'http://localhost:3000',
    INTERCOM_APP_ID: 'fel1xywj'
};

const ProductionConfig: AppConfig = {
    BASE_URL: 'https://auth.alvalabs.io',
    API_BASE_URL: 'https://auth.alvalabs.io',
    ENVIRONMENT: 'production',
    ALVA_APP_BASE_URL: 'https://app.alvalabs.io',
    INTERCOM_APP_ID: 'xjr8vtw1'
};

const StagingConfig: AppConfig = {
    BASE_URL: 'https://auth-staging.alvalabs.io',
    API_BASE_URL: 'https://auth-staging.alvalabs.io',
    ENVIRONMENT: 'staging',
    ALVA_APP_BASE_URL: 'https://staging.app.alvalabs.io',
    INTERCOM_APP_ID: 'fel1xywj'
};

function isLocal() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
}

function isStaging() {
    return window.location.hostname.includes('auth-staging.alvalabs.io');
}

export function getConfig(): AppConfig {
    if (isLocal()) {
        return LocalConfig;
    }
    if (isStaging()) {
        return StagingConfig;
    }

    return ProductionConfig;
}

export function createUrl(endpoint: string): string {
    const config = getConfig();
    return `${config.API_BASE_URL}${endpoint}`;
}
