import {defineMessages} from "react-intl";

export default defineMessages({
    genericTitle: {
        id: 'signInPage.genericTitle'
    },
    clientTitle: {
        id: 'signInPage.clientTitle'
    }
});
