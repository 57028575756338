import styled from 'styled-components';
import {DefaultTextStyleCss} from "./DefaultTextStyle";
import {fontSizeTokens} from "../../tokens/fontSize";
import {lineHeightTokens} from "../../tokens/lineHeight";


export const H1 = styled.h1`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.largest};
    line-height: ${lineHeightTokens.largest};
`;

export const H2 = styled.h2`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.larger};
    line-height: ${lineHeightTokens.larger};
`;

export const H3 = styled.h3`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.large};
    line-height: ${lineHeightTokens.large};
`;

export const H4 = styled.h4`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.medium};
    line-height: ${lineHeightTokens.small};
`;
