import React from "react";
import styled, {css} from 'styled-components';
import {ButtonBase, ButtonBaseProps} from "../../primitives/buttons/ButtonBase";
import {greyColorTokens, primaryColorTokens, tertiaryColorTokens} from "../../tokens/color";
import {borderRadiusTokens} from "../../tokens/borderRadius";


interface PrimaryButtonStyleProps extends ButtonBaseProps {
    isLoading?: boolean;
    isSelected?: boolean;
    lookDisabled?: boolean;
}


const PrimaryButtonStyle = styled(ButtonBase)<PrimaryButtonStyleProps>`
  background-color: ${primaryColorTokens.yellow()};
  border-radius: ${borderRadiusTokens.large};
  border: 1px solid transparent; // Needs to be here, to make all buttons the same size
  
  ${({lookDisabled}) => lookDisabled ? (css`
    background-color: ${tertiaryColorTokens.yellow000()};
    &:hover {
       background-color: ${tertiaryColorTokens.yellow000()};
    }
  `) : ''}
  
  ${({isLoading}) => isLoading ? (css`    
    &:after {
      border-color: ${greyColorTokens.grey000()};
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  `) : ''}
  
  ${({isSelected}) => isSelected ? `
    background-color: ${primaryColorTokens.yellow()};
    &:hover {
      background-color: ${primaryColorTokens.yellow()} !important;
    }
    &:active {
      background-color: ${primaryColorTokens.yellow()} !important;
    }
  ` : ''};
        
  &:hover:focus,
  &:hover {
    background-color: ${tertiaryColorTokens.yellow300()};
  }
  
  &:active:focus:hover,
  &.active,
  &:active {
    background-color: ${primaryColorTokens.yellow()};
  }
  
  &.selected:focus:hover,
  &.selected:focus,
  &.selected:active,
  &.selected {
    background-color: ${primaryColorTokens.yellow()};
  }
      
  &:disabled:hover,
  &:disabled {
    background-color: ${primaryColorTokens.yellow()};
    opacity: 0.4;
  }
`;

interface PrimaryButtonProps extends PrimaryButtonStyleProps {
    isSmall?: boolean;
}

export const PrimaryButton = (props: PrimaryButtonProps) => (
    <PrimaryButtonStyle isSmall={false} {...props}/>
);
