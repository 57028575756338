import {AVAILABLE_LOCALES, DEFAULT_LOCALE, LOCAL_STORAGE_LOCALE_KEY} from '../constants';
import {PreferredUserLanguages} from "../../types";


export function getLocaleState(): string {
    const defaultLocale = getDefaultLocale();
    if (!window.localStorage) {
        return defaultLocale;
    }

    let localStorageLocale: string | null;
    try {
        localStorageLocale = localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY);
        if (localStorageLocale && AVAILABLE_LOCALES.find(locale => locale === localStorageLocale)) {
            return localStorageLocale;
        }
    } catch (e) {
        return defaultLocale;
    }
    return defaultLocale;
}

function getDefaultLocale(): string {
    const userLanguages = {
        languages: navigator.languages,
        language: navigator.language,
        // @ts-ignore
        userLanguage: navigator.userLanguage,
        // @ts-ignore
        browserLanguage: navigator.browserLanguage
    };

    return getPreferredUserLanguage(userLanguages);
}

export function getPreferredUserLanguage(userLanguages: PreferredUserLanguages): string {
    const {languages, language, userLanguage, browserLanguage} = userLanguages;
    if (languages && languages.length) {
        const matchedLocale = languages.find((locale: string) => {
            return matchLocaleAgainstAvailableLocales(locale);
        });

        if (matchedLocale) {
            return matchedLocale.slice(0, AVAILABLE_LOCALES[0].length);
        }
    }

    const locale = userLanguage || browserLanguage || language;

    if (locale) {
        const matchedLocale = matchLocaleAgainstAvailableLocales(locale);

        if (matchedLocale) {
            return matchedLocale;
        }
    }

    return DEFAULT_LOCALE;
}

function matchLocaleAgainstAvailableLocales(locale: string): string | undefined {
    return AVAILABLE_LOCALES.find((availableLocale: string) => {
        if (locale.startsWith(availableLocale)) {
            return availableLocale;
        }

        return undefined;
    });
}
