export const deviceBreakPointTokens = {
    MOBILE: {
        min: '0',
        max: '767px'
    },
    TABLET: {
        min: '768px',
        max: '991px'
    },
    DESKTOP: {
        min: '992px'
    }
};
