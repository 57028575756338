import React from 'react';
import {useAuthorizeErrorParameters} from "./hooks/useAuthorizeErrorParameters";
import {ErrorPage} from "../ErrorPage";

export function AuthorizeError() {
    const authorizeErrorParameters = useAuthorizeErrorParameters();
    return (
        <ErrorPage
            title={authorizeErrorParameters.error}
            description={authorizeErrorParameters.errorDescription}
        />
    );
}
