import {CompletedAuthorization} from "../../../models/CompletedAuthorization";
import {stringify} from "qs";

type  useRedirectToClientReturnType = (completedAuthorization: CompletedAuthorization) => void;

export function useRedirectToClient(): useRedirectToClientReturnType {
    return redirectToClient
}

function redirectToClient(completedAuthorization: CompletedAuthorization) {
    const queryString = stringify({
        state: completedAuthorization.state,
        code: completedAuthorization.code
    });
    window.location.href = `${completedAuthorization.redirectUri}?${queryString}`
}
