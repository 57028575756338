import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
import blackAlvaLogo from './assets/blackAlvaLogo.svg';
import whiteAlvaLogo from './assets/whiteAlvaLogo.svg';
import {deviceBreakPointTokens} from "../../../../tokens/deviceBreakpoints";
import {greyColorTokens} from "../../../../tokens/color";
import {LanguageDropdown} from "../../../../elements/dropdowns/LanguageDropdown";

const transparentLanguageDropdownCss = css`
  @media only screen and (min-width: ${deviceBreakPointTokens.DESKTOP.min}) {
    background-color: transparent;
    color: ${greyColorTokens.white000()};
    border-color: ${greyColorTokens.white000()};
    &:hover {
       color: ${greyColorTokens.grey600()};
       border-color: ${greyColorTokens.grey600()};
       &::placeholder {
        color: ${greyColorTokens.grey600()};
       }
    }
    &:focus {
      color: ${greyColorTokens.white000()};
      border-color: ${greyColorTokens.white000()};
      outline: none;
      &::placeholder {
        color: ${greyColorTokens.white000()};
      }
    }
    &::placeholder {
      color: ${greyColorTokens.white000()};
    }
  }
`;

interface LandingPageLayoutHeaderProps {
    isWhite: boolean;
    withLogo: boolean;
    withLanguageSelector: boolean;
}

export function LandingPageLayoutHeader({isWhite, withLogo, withLanguageSelector}: LandingPageLayoutHeaderProps) {
    const backgroundImage = isWhite ? blackAlvaLogo : whiteAlvaLogo;
    return (
        <Wrapper withLogo={withLogo}>
            {withLogo ? (
                <AlvaLogo backgroundImage={backgroundImage}/>
            ) : null}
            {withLanguageSelector ? (
                <LanguageSelectorWrapper>
                    <LanguageDropdown
                        inputCss={!isWhite ? transparentLanguageDropdownCss : ''}
                        fullWidth={true}
                    />
                </LanguageSelectorWrapper>
            ) : null}
        </Wrapper>
    );
}

LandingPageLayoutHeader.defaultProps = {
    withLogo: true,
    withLanguageSelector: true
};

const Wrapper = styled.div<{ withLogo: boolean; children: ReactNode; }>`
  height: 48px;
  width: 100%;
  flex: 0 0;
  display: flex;
  align-items: center;
  ${({withLogo}) => withLogo ? `
    justify-content: space-between;
  ` : `
    justify-content: flex-end;
  `}
  background-color: transparent;
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    padding-top: 20px;
  }
`;

const AlvaLogo = styled.div<{ backgroundImage: string; }>`
  height: 40px;
  width: 40px;
  background: url(${({backgroundImage}) => backgroundImage}) center center no-repeat;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    background: url(${blackAlvaLogo}) center center no-repeat;
  }
`;

const LanguageSelectorWrapper = styled.div`
  width: 140px;
`;
