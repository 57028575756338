import React, {Fragment, useCallback} from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';
import messages, {errorMessages} from './messages';
import {useSignInFormLogic} from './logic';
import {Caption} from "../../../../componentLibrary/primitives/textStyles/Captions";
import {tertiaryColorTokens} from "../../../../componentLibrary/tokens/color";
import {getConfig} from "../../../../config";
import {TextField} from "../../../../componentLibrary/elements/inputs/TextField";
import {PrimaryButton} from "../../../../componentLibrary/elements/buttons/PrimaryButton";
import {PlainButton} from "../../../../componentLibrary/elements/buttons/PlainButton";
import {deviceBreakPointTokens} from "../../../../componentLibrary/tokens/deviceBreakpoints";
import {borderRadiusTokens} from "../../../../componentLibrary/tokens/borderRadius";
import {H4} from "../../../../componentLibrary/primitives/textStyles/Headings";

const config = getConfig();
const RECOVER_PASSWORD_LINK = config.ALVA_APP_BASE_URL + '/login/recover';


interface SignInFormProps {
    submitForm: (email: string, password: string) => void;
    errorMessage: string | null;
    loading: boolean;
}

const errors = errorMessages as any;

export function SignInForm({submitForm, errorMessage, loading}: SignInFormProps) {
    const {missingRequiredFields, emailFieldRef, passwordFieldRef, handleSubmit} = useSignInFormLogic(submitForm);

    const recoverPassword = useCallback(() => {
        window.open(RECOVER_PASSWORD_LINK, '_blank');
        window.focus();
    }, []);

    return (
        <Fragment>
            {missingRequiredFields ? (
                <ErrorMessage>
                    <Caption color={tertiaryColorTokens.red300()}>
                        <FormattedMessage {...messages.missingRequiredFields}/>
                    </Caption>
                </ErrorMessage>
            ) : null}

            {errorMessage && errors[errorMessage] ? (
                <ErrorMessage>
                    <Caption color={tertiaryColorTokens.red300()}>
                        <FormattedMessage {...errors[errorMessage]}/>
                    </Caption>
                </ErrorMessage>
            ) : null}

            {errorMessage && !errors[errorMessage] ? (
                <ErrorMessage>
                    <Caption color={tertiaryColorTokens.red300()}>
                        <FormattedMessage {...messages.unknownError}/>
                    </Caption>
                </ErrorMessage>
            ) : null}

            {(!missingRequiredFields && !errorMessage) ? (
                <ErrorMessagePlaceholder/>
            ) : null}

            <Wrapper>
                <FormHeader>
                    <FormattedMessage {...messages.title}/>
                </FormHeader>

                <form onSubmit={handleSubmit} style={{width: '100%'}}>
                    <FormField>
                        <FormattedMessage {...messages.emailPlaceholder}>
                            {(placeholder) => (
                                <TextField
                                    data-test-id="email"
                                    type="email"
                                    fullWidth={true}
                                    placeholder={placeholder}
                                    innerRef={emailFieldRef}
                                    label={<FormattedMessage {...messages.email}/>}
                                />
                            )}
                        </FormattedMessage>
                    </FormField>

                    <FormField>
                        <FormattedMessage {...messages.password}>
                            {(placeholder) => (
                                <TextField
                                    data-test-id="password"
                                    type="password"
                                    fullWidth={true}
                                    placeholder={placeholder}
                                    innerRef={passwordFieldRef}
                                    label={<FormattedMessage {...messages.password}/>}
                                />
                            )}
                        </FormattedMessage>
                    </FormField>

                    <FormField>
                        <PrimaryButton
                            type="submit"
                            isLoading={loading}
                            disabled={loading}
                            isFullWidth={true}
                        >
                            <FormattedMessage {...messages.continue}/>
                        </PrimaryButton>
                    </FormField>

                    <FormField style={{display: 'flex', justifyContent: 'center'}}>
                        <PlainButton onClick={recoverPassword}>
                            <FormattedMessage {...messages.recover}/>
                        </PlainButton>
                    </FormField>

                </form>
            </Wrapper>
        </Fragment>
    );
}

const Wrapper = styled.div`
   padding: 0 40px 60px 40px;
   display: flex;
   flex-direction: column;
   @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
     padding: 0;
   }
`;

const FormField = styled.div`
  margin-bottom: 24px;
  width: 100%;
`;

const ErrorMessage = styled.div`
  background-color: ${tertiaryColorTokens.red000()};
  border-radius: ${borderRadiusTokens.small};
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    width: 100vw;
    transform: translateX(-20px);
  }
`;

const ErrorMessagePlaceholder = styled.div`
  height: 60px;
  width: 100%;
`;

const FormHeader = styled(H4)`
  margin-bottom: 24px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
`;
