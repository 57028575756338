import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {LanguageProvider} from "./localization/LanguageProvider";
import {translationMessages} from "./localization/i18n";
import "./fonts/EuclidCircularA-Regular-WebS.ttf";
import {Intercom} from "./services/intercom";
import {WithAuthenticatedUser} from "./services/authenticatedUser/WithAuthenticatedUser";

ReactDOM.render(
    <React.StrictMode>
        <LanguageProvider messages={translationMessages}>
            <BrowserRouter>
                <WithAuthenticatedUser>
                    <App/>
                    <Intercom/>
                </WithAuthenticatedUser>
            </BrowserRouter>
        </LanguageProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
