import styled from 'styled-components';
import {DefaultTextStyleCss} from "./DefaultTextStyle";
import {fontSizeTokens} from "../../tokens/fontSize";
import {lineHeightTokens} from "../../tokens/lineHeight";
import {fontWeightTokens} from "../../tokens/fontWeight";

export const S1 = styled.span`
    ${DefaultTextStyleCss};
    font-weight: ${fontWeightTokens.medium};
    font-size: ${fontSizeTokens.small};
    line-height: ${lineHeightTokens.small};
`;

export const S2 = styled.span`
    ${DefaultTextStyleCss};
    font-weight: ${fontWeightTokens.medium};
    font-size: ${fontSizeTokens.smaller};
    line-height: ${lineHeightTokens.small};
`;
