import styled from 'styled-components';
import {Caption} from "../../primitives/textStyles/Captions";
import {greyColorTokens} from "../../tokens/color";
import {ReactNode} from "react";

export const TextFieldHelpText = styled(Caption)<{ children: ReactNode; }>`
  display: block;
  margin-top: 8px;
  color: ${greyColorTokens.grey400()};
`;
