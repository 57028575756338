import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {SignIn} from "./pages/SignIn";
import {AuthorizeError} from "./pages/AuthorizeError";
import {NotFound} from "./pages/NotFound";
import {ApproveAuthorization} from "./pages/ApproveAuthorization";

function App() {
    return (
        <Switch>
            <Route path='/sign-in' render={() => <SignIn/>}/>
            <Route path='/approve' render={() => <ApproveAuthorization/>}/>
            <Route path='/authorize-error' render={() => <AuthorizeError/>}/>
            <Route path='*' render={() => <NotFound/>}/>
        </Switch>
    );
}

export default App;
