import React, {useCallback} from 'react';
import styled from 'styled-components';
import close from './assets/close.svg';

interface ClearDropdownSelectionProps {
    onClick?: () => void;
}

export function ClearDropdownSelection({onClick}: ClearDropdownSelectionProps) {
    const clearSelection = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <CloseIcon data-testid='clear-selection' onClick={clearSelection}/>
    );
}

const CloseIcon = styled.div`
  cursor: pointer;
  background: transparent url(${close}) no-repeat center center;
  background-size: 12px 12px;
  height: 24px;
  width: 24px;
  pointer-events: initial;
  :hover {
    background-color: rgba(0,0,0,0.04);
    border-radius: 10px;
  }
`;
