import {createContext, useCallback, useContext, useReducer} from 'react';
import {UserContextType, UserState} from "./types";
import {User} from "../../models/User";


const initialState: UserState = {authenticatedUser: null, setAuthenticatedUser: () => undefined};
export const AuthenticatedUserContext = createContext(initialState);

function reducer(state: UserState, user: User): UserState {
    return Object.assign({}, state, {authenticatedUser: user})
}

export function useCreateAuthenticatedUserContext(): UserContextType {
    const [{authenticatedUser}, dispatch] = useReducer(reducer, initialState);

    const setAuthenticatedUser = useCallback((user: User) => {
        dispatch(user);
    }, []);

    return {
        authenticatedUser,
        setAuthenticatedUser
    }
}

export function useAuthenticatedUserContext(): UserState {
    return useContext(AuthenticatedUserContext);
}
