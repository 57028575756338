import {useCallback, useState} from "react";
import {createUrl} from "../config";
import {ApiError} from "../models/ApiError";
import {parseApiError} from "./utils";
import {CompletedAuthorization} from "../models/CompletedAuthorization";

type useCompleteAuthorizationReturnType = [
    (organizationId: number) => Promise<CompletedAuthorization>,
    {
        loading: boolean;
        error?: ApiError
    }
];

export function useCompleteAuthorization(requestId: string | null): useCompleteAuthorizationReturnType {
    const [error, setError] = useState<ApiError | undefined>();
    const [loading, setLoading] = useState(false);
    const completeAuthorization = useCallback((organizationId) => {
        return new Promise<CompletedAuthorization>((resolve, reject) => {
            setLoading(true);
            setError(undefined);
            fetch(createUrl('/v1/api/users.completeAuthorization'), {
                method: 'POST',
                body: JSON.stringify({
                    organization_id: organizationId,
                    request_id: requestId
                }),
                credentials: 'include'
            }).then(response => {
                response.json().then(body => {
                    if (response.status === 200) {
                        setLoading(false);
                        resolve(parseCompletedAuthorization(body));
                        return;
                    }
                    const apiError = parseApiError(body);
                    setError(apiError);
                    setLoading(false);
                    reject(apiError);
                });

            }).catch(reason => {
                setLoading(false);
                const apiError = new ApiError('UNKNOWN', reason.toString());
                setError(apiError);
                reject(apiError);
            });
        });
    }, [requestId]);
    return [completeAuthorization, {error, loading}]
}

function parseCompletedAuthorization(body: any): CompletedAuthorization {
    return {
        redirectUri: body.data.redirect_uri,
        code: body.data.code,
        state: body.data.state ? body.data.state : undefined
    }
}
