import {useCallback, useEffect, useState} from "react";
import {ApiError} from "../models/ApiError";
import {createUrl} from "../config";
import {Organization} from "../models/Organization";
import {createUnknownError, parseApiError} from "./utils";

type useOrganizationsReturnType = {
    organizations: Organization[];
    error?: ApiError;
    loading: boolean;
};

export function useOrganizations(): useOrganizationsReturnType {
    const [error, setError] = useState<ApiError | undefined>();
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const fetchOrganizations = useCallback(() => {
        setLoading(true);
        setError(undefined);
        fetch(createUrl('/v1/api/users.organizations'), {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            response.json().then(body => {
                if (response.status === 200) {
                    setOrganizations(parseOrganizations(body))
                } else {
                    setError(parseApiError(body))
                }
                setLoading(false);
            });
        }).catch(reason => {
            setError(createUnknownError(reason));
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        fetchOrganizations();
    }, [fetchOrganizations]);
    return {organizations, error, loading}
}

function parseOrganizations(body: any): Organization[] {
    return body.data.map((organization: any) => ({
        id: organization.id,
        name: organization.name,
        userIsAllowedToConfigureIntegration: organization.user_is_allowed_to_configure_integration
    }))
}