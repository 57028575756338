import {createContext, useCallback, useContext, useReducer} from 'react';
import {LOCAL_STORAGE_LOCALE_KEY} from '../constants';
import {getLocaleState} from "./helpers";
import {LocaleAction, LocaleContextType, LocaleState} from "./types";


const LOCALE_ACTIONS = {
    CHANGE_LOCALE: 'CHANGE_LOCALE'
};

const initialState: LocaleState = {locale: getLocaleState(), changeLocale: () => undefined};
export const LocaleContext = createContext(initialState);

function reducer(state: LocaleState, action: LocaleAction): LocaleState {
    switch (action.type) {
        case LOCALE_ACTIONS.CHANGE_LOCALE:
            if (window.localStorage) {
                localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, action.locale);
            }

            return Object.assign({}, state, {locale: action.locale});
        default:
            return state;
    }
}

export function useCreateLocaleContext(): LocaleContextType {
    const [{locale}, dispatch] = useReducer(reducer, initialState);

    const changeLocale = useCallback((newLocale: string) => {
        dispatch({type: LOCALE_ACTIONS.CHANGE_LOCALE, locale: newLocale});
    }, []);

    return {
        locale,
        changeLocale
    }
}

export function useLocaleContext(): LocaleState {
    return useContext(LocaleContext);
}
