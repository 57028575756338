import styled from 'styled-components';
import {PlainButtonBase} from "../../primitives/buttons/ButtonBase";
import {tertiaryColorTokens} from "../../tokens/color";
import {ReactNode} from "react";


interface PlainButtonProps {
    isSelected?: boolean;
    onClick?: () => void;
    children: ReactNode;
}

export const PlainButton = styled(PlainButtonBase)<PlainButtonProps>`
  color: ${tertiaryColorTokens.blue200()};
        
  &:hover:focus,
  &:hover {
    color: ${tertiaryColorTokens.blue200()};
  }
  
  &:active:focus:hover,
  &.active,
  &:active {
    color: ${tertiaryColorTokens.blue300()};
  }
  
  &.selected:focus:hover,
  &.selected:focus,
  &.selected:active,
  &.selected {
    color: ${tertiaryColorTokens.blue200()};
  }
  
  &:disabled:hover,
  &:disabled {
    color: ${tertiaryColorTokens.blue100()};
  }
  
  ${({isSelected}) => isSelected ? `
    color: ${tertiaryColorTokens.blue300()};
    &:hover {
      color: ${tertiaryColorTokens.blue300()} !important;
    }
    &:active {
      color-color: ${tertiaryColorTokens.blue300()} !important;
    }
  ` : ''};
`;
