import styled from 'styled-components';
import {TextStyleProps} from "./types";
import {DefaultTextStyleCss} from "./DefaultTextStyle";
import {fontSizeTokens} from "../../tokens/fontSize";
import {lineHeightTokens} from "../../tokens/lineHeight";
import {greyColorTokens} from "../../tokens/color";

export const Caption = styled.span<TextStyleProps>`
    ${DefaultTextStyleCss};
    font-size: ${fontSizeTokens.smallest};
    line-height: ${lineHeightTokens.smallest};
    color: ${({color}) => color};
`;

Caption.defaultProps = {
    color: greyColorTokens.grey000()
};
