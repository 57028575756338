import {AuthorizeErrorParameters} from "../types/AuthorizeErrorParameters";
import {useLocation} from 'react-router-dom';
import qs from 'qs';

export function useAuthorizeErrorParameters(): AuthorizeErrorParameters {
    const location = useLocation();
    const params = qs.parse(location.search, {ignoreQueryPrefix: true});
    return {
        error: params.error ? params.error.toString() : null,
        errorDescription: params.error_description ? params.error_description.toString() : null
    }
}
