import styled from 'styled-components';
import {tertiaryColorTokens} from "../../tokens/color";
import {P2} from "../../primitives/textStyles/Paragraphs";
import {ReactNode} from "react";

export const TextFieldLabel = styled(P2)<{ isRequired?: boolean; children: ReactNode; }>`
  display: block;
  margin-bottom: 8px;
  margin-top: 0;
  ${({isRequired}) => isRequired ? `
    :after {
      color: ${tertiaryColorTokens.red300()};
      content: " *";
    }
  ` : ''};
`;
