import React from 'react';
import styled, {keyframes} from 'styled-components';
import {greyColorTokens} from "../../tokens/color";

export function DropdownLoadingState() {
    return (
        <Wrapper data-testid={'dropdown-loading-state'}>
            <FirstLoadingDot/>
            <SecondLoadingDot/>
            <LoadingDot/>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const bouncedelay = () => keyframes`
  0%, 80%, 100% { transform: scale(0) }
  40% { transform: scale(1.0) }
`;

const LoadingDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${greyColorTokens.grey600()};
  border-radius: 100%;
  display: inline-block;
  animation: ${bouncedelay()} 1.4s infinite ease-in-out both;
`;

const FirstLoadingDot = styled(LoadingDot)`
  animation-delay: -0.32s;
`;

const SecondLoadingDot = styled(LoadingDot)`
  animation-delay: -0.16s;
`;
