import styled from 'styled-components';
import {Caption} from "../../primitives/textStyles/Captions";
import {tertiaryColorTokens} from "../../tokens/color";
import {ReactNode} from "react";

export const TextFieldErrorMessage = styled(Caption)<{ children: ReactNode; }>`
  display: block;
  margin-top: 8px;
  color: ${tertiaryColorTokens.red300()};
`;
