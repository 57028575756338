import {User} from "./models/User";

interface GetUserNameOptions {
    fallbackOnEmail: boolean;
    guessFromEmail: boolean;
    firstNameOnly: boolean;
}

const defaultOptions: GetUserNameOptions = {
    fallbackOnEmail: true,
    guessFromEmail: false,
    firstNameOnly: false
};

function capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function getUserName(user: User | null, options: GetUserNameOptions = defaultOptions): string {
    const {fallbackOnEmail, guessFromEmail, firstNameOnly} = options;
    if (!user) {
        return '';
    }
    if (user.firstName) {
        return `${user.firstName}${user.lastName && !firstNameOnly ? ' ' + user.lastName : ''}`;
    }
    if (fallbackOnEmail && user.email) {
        return user.email;
    }
    if (guessFromEmail && user.email) {
        return capitalizeFirstLetter(user.email.split('@')[0]);
    }
    return '';
}
