/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import enTranslationMessages from '../translations/en.json';
import svTranslationMessages from '../translations/sv.json';
import {DEFAULT_LOCALE} from "./LanguageProvider/constants";
import {TranslationMessagesType} from './types';


const enTranslations = (enTranslationMessages as TranslationMessagesType);
const svTranslations = (svTranslationMessages as TranslationMessagesType);

function getDefaultMessages(): TranslationMessagesType {
    return Object.keys(enTranslations).reduce((formattedMessages: TranslationMessagesType, key: string) => {
        return Object.assign(formattedMessages, {
            [key]: enTranslations[key]
        });
    }, {});
}

export function formatTranslationMessages(locale: string, messages: TranslationMessagesType): TranslationMessagesType {
    const defaultMessages = getDefaultMessages();

    return Object.keys(defaultMessages).reduce((formattedMessages: TranslationMessagesType, key: string) => {
        const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
            ? defaultMessages[key]
            : messages[key];

        return Object.assign(formattedMessages, {[key]: formattedMessage});
    }, {});
}

export const translationMessages = {
    en: formatTranslationMessages('en', enTranslations),
    sv: formatTranslationMessages('sv', svTranslations)
};
