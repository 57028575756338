import {parse, stringify} from "qs";
import {AuthorizeParameters} from "../../models/AuthorizeParameters";

export function parseSignInParametersFromQueryString(queryString: string): AuthorizeParameters {
    const params = parse(queryString, {ignoreQueryPrefix: true});
    return {
        flow: params.flow ? params.flow.toString() : null,
        rid: params.rid ? params.rid.toString() : null
    }
}

export function formatSignInParametersAsQueryString(signInParameters: { flow: string | null; rid: string | null; }): string {
    return stringify(signInParameters)
}
