import {defineMessages} from 'react-intl';


export default defineMessages({
    missingRequiredFields: {
        id: 'signIn.errors.missingRequiredFields'
    },
    unknownError: {
        id: 'signIn.errors.unknownError'
    },
    title: {
        id: 'signIn.title'
    },
    emailPlaceholder: {
        id: 'signIn.emailPlaceholder'
    },
    email: {
        id: 'signIn.email'
    },
    password: {
        id: 'signIn.password'
    },
    continue: {
        id: 'signIn.continue'
    },
    recover: {
        id: 'signIn.recover'
    }
});

export const errorMessages = defineMessages({
    MALFORMED_SIGN_IN_CREDENTIALS: {
        id: 'signIn.errors.MALFORMED_SIGN_IN_CREDENTIALS'
    },
    INVALID_PASSWORD: {
        id: 'signIn.errors.INVALID_PASSWORD'
    },
    NO_PASSWORD: {
        id: 'signIn.errors.NO_PASSWORD'
    },
    NO_SUCH_USER: {
        id: 'signIn.errors.NO_SUCH_USER'
    },
    USER_IS_NOT_ACTIVATED: {
        id: 'signIn.errors.USER_NOT_ACTIVATED'
    }
});
