import React, {Fragment, ReactNode} from 'react';
import {useAuthorizeParameters} from "../../hooks/useAuthorizeParameters";
import {ErrorPage} from "../ErrorPage";
import {LandingPageLayout} from "../../componentLibrary/blocks/LandingPageLayout";
import styled from "styled-components";
import {deviceBreakPointTokens} from "../../componentLibrary/tokens/deviceBreakpoints";
import {useClientForRequest} from "../../api/useClientForRequest";
import {FormattedMessage} from "react-intl";
import messages from "./messages";
import {DefaultTextStyleCss} from "../../componentLibrary/primitives/textStyles/DefaultTextStyle";
import {greyColorTokens} from "../../componentLibrary/tokens/color";
import {S1, S2} from "../../componentLibrary/primitives/textStyles/SubTitles";
import {Caption} from "../../componentLibrary/primitives/textStyles/Captions";
import syncLogo from "./assets/syncIcon.svg";
import alvaLogo from "./assets/alvaLogo.svg";
import {Dropdown} from "../../componentLibrary/elements/dropdowns/Dropdown";
import {PrimaryButton} from "../../componentLibrary/elements/buttons/PrimaryButton";
import {OrganizationMenuItem} from "../../componentLibrary/elements/dropdowns/DropdownMenuItems";
import {DropdownIndicator} from "../../componentLibrary/elements/dropdowns/DropdownIndicator";
import {useApproveAuthorizationLogic} from "./logic";

export function ApproveAuthorization() {
    const parameters = useAuthorizeParameters();
    const {client, error: clientError, loading: loadingClient} = useClientForRequest(parameters.rid);
    const {
        loadingOrganizations,
        loading,
        organizationsError,
        error,
        selectedOrganization,
        setSelectedOrganization,
        approveAuthorization,
        manageableOrganizations,
        organizations
    } = useApproveAuthorizationLogic(parameters);

    if (error) {
        return <ErrorPage title={error}/>;
    }

    if (organizationsError) {
        return <ErrorPage title={organizationsError.code} description={organizationsError.message}/>;
    }

    if (clientError) {
        return <ErrorPage title={clientError.code} description={clientError.message}/>;
    }

    if (loadingOrganizations || loadingClient) {
        return <LandingPageLayout withLogoInHeader={false}/>;
    }

    if (!client) {
        return <ErrorPage title={'No client'}/>;
    }

    if (manageableOrganizations.length === 0) {
        return (
            <ErrorPage
                withLanguageSelector={true}
                title={<FormattedMessage {...messages.noAccessTitle}/>}
                description={
                    <Fragment>
                        <FormattedMessage {...messages.noAccessDescription}/>
                        <br/>
                        <br/>
                        <FormattedMessage {...messages.contactAdministrator}/>
                    </Fragment>
                }
            />
        );
    }

    return (
        <LandingPageLayout withLogoInHeader={false}>
            <OuterWrapper>
                <Wrapper>
                    <Column>
                        <LogoWrapper>
                            {client ? (
                                <Logo imageUrl={client.logo} size={32}/>
                            ) : null}
                            <Logo imageUrl={syncLogo} size={16}/>
                            <Logo imageUrl={alvaLogo} size={32}/>
                        </LogoWrapper>
                        <Title>
                            <FormattedMessage {...messages.title} values={{clientName: client.name}}/>
                        </Title>
                        <Permission
                            title={<FormattedMessage {...messages.manageJobPositionsTitle}/>}
                            description={
                                <FormattedMessage
                                    {...messages.manageJobPositionsDescription}
                                    values={{clientName: client.name}}
                                />
                            }
                        />
                        <Permission
                            title={<FormattedMessage {...messages.requestAssessmentsTitle}/>}
                            description={
                                <FormattedMessage
                                    {...messages.requestAssessmentsDescription}
                                    values={{clientName: client.name}}
                                />
                            }
                        />
                        <Permission
                            title={<FormattedMessage {...messages.viewJobPositionsTitle}/>}
                            description={<FormattedMessage {...messages.viewJobPositionsDescription}/>}
                        />
                        <Permission
                            title={<FormattedMessage {...messages.viewCandidatesTitle}/>}
                            description={<FormattedMessage {...messages.viewCandidatesDescription}/>}
                        />
                    </Column>
                    <Column>
                        {manageableOrganizations.length === 1 ? (
                            <Fragment>
                                <FormattedMessage {...messages.organization}/>
                                <S1 style={{marginTop: '12px'}}>
                                    {manageableOrganizations[0].name}
                                </S1>
                            </Fragment>
                        ) : (
                            <FormattedMessage {...messages.organizationPlaceholder}>
                                {(placeholder) => (
                                    <Dropdown
                                        items={organizations}
                                        placeholder={placeholder}
                                        onSelect={setSelectedOrganization}
                                        selectedItem={selectedOrganization}
                                        label={<FormattedMessage {...messages.organization}/>}
                                        fullWidth={true}
                                        isClearable={true}
                                        components={{Item: OrganizationMenuItem, Indicator: DropdownIndicator}}
                                    />
                                )}
                            </FormattedMessage>
                        )}
                        <Spacing/>
                        <PrimaryButton
                            disabled={!selectedOrganization}
                            onClick={approveAuthorization}
                            isLoading={loading}
                        >
                            <FormattedMessage {...messages.confirmAccess}/>
                        </PrimaryButton>
                    </Column>
                </Wrapper>
            </OuterWrapper>
        </LandingPageLayout>
    );
}

const OuterWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin-top: 32px;
  justify-content: space-between;
  flex: 1;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    justify-content: flex-start;
    flex-direction: column;
  }
  @media only screen and (max-width: ${deviceBreakPointTokens.MOBILE.max}) {
    width: 100%;
  }
  @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.min}) and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    width: 440px;
    align-items: center;
  }
  @media only screen and (min-width: ${deviceBreakPointTokens.TABLET.max}) and (max-width: 1024px) {
    padding: 0 40px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 360px;
  flex: 1;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    width: 100%;
  }
  &:last-child {
    padding-top: 60px;
    @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
      padding-top: 40px;
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 28px;
  align-items: center;
`;

const Logo = styled.div<{size: number; imageUrl: string;}>`
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  background: url(${({imageUrl}) => imageUrl}) center center no-repeat;
  background-size: contain;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
`;

const Title = styled.div`
  ${DefaultTextStyleCss}
  color: ${greyColorTokens.grey000()};
  font-size: 60px;
  line-height: 58px;
  letter-spacing: -3px;
  word-wrap: break-word;
  @media only screen and (max-width: ${deviceBreakPointTokens.TABLET.max}) {
    font-size: 48px;
    line-height: 48px;
  }
  margin-bottom: 20px;
`;

interface PermissionProps {
    title: ReactNode;
    description: ReactNode;
}

function Permission({title, description}: PermissionProps) {
    return (
        <PermissionWrapper>
            <PermissionTitle>
                {title}
            </PermissionTitle>
            <Caption color={greyColorTokens.grey200()}>
                {description}
            </Caption>
        </PermissionWrapper>
    );
}

const PermissionWrapper = styled.div`
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
`;

const PermissionTitle = styled(S2)`
  margin-bottom: 4px;
`;

const Spacing = styled.div`
  width: 100%;
  height: 24px;
`;
