import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {matchSorter} from 'match-sorter';
import {
    DisplayValueLogicReturnType,
    EqualityLogicReturnType,
    FilteringLogicReturnType,
    ItemIdReturnType,
    ItemType,
    MenuLogicReturnType,
    SearchStringLogicReturnType
} from "./types";

export function useSearchStringLogic(
    controlledSearchString?: string,
    onSearchStringChange?: (s?: string) => void
): SearchStringLogicReturnType {
    const [uncontrolledSearchString, setUncontrolledSearchString] = useState('');
    const isSearchStringControlled = controlledSearchString !== undefined;
    const searchString = isSearchStringControlled ? controlledSearchString : uncontrolledSearchString;

    useEffect(() => {
        if (!isSearchStringControlled && onSearchStringChange) {
            onSearchStringChange(searchString);
        }
    }, [isSearchStringControlled, onSearchStringChange, searchString]);

    const handleInputChange = useCallback((e) => {
        const value = e.target.value;
        if (isSearchStringControlled && onSearchStringChange) {
            onSearchStringChange(value);
        } else {
            setUncontrolledSearchString(value);
        }
    }, [setUncontrolledSearchString, isSearchStringControlled, onSearchStringChange]);

    const clearSearchString = useCallback(() => {
        if (isSearchStringControlled && onSearchStringChange) {
            onSearchStringChange('');
        } else {
            setUncontrolledSearchString('');
        }
    }, [isSearchStringControlled, onSearchStringChange]);

    return {
        searchString,
        clearSearchString,
        handleInputChange
    };
}

export function useFilteringLogic(
    items: Array<ItemType>,
    disableDefaultFiltering?: boolean,
    searchString?: string,
    customFilter?: (s?: string) => Array<ItemType>
): FilteringLogicReturnType {
    const itemsToShow = useMemo(() => {
        if (disableDefaultFiltering) {
            return items;
        }
        if (customFilter) {
            return customFilter(searchString);
        }
        return searchString ? matchSorter(items, searchString, {keys: ['name']}) : items;
    }, [disableDefaultFiltering, items, searchString, customFilter]);
    return {itemsToShow};
}

export function useMenuLogic(clearSearchString: () => void, onBlur?: () => void): MenuLogicReturnType {
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const blur = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    }, []);

    const handleOnFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleOnBlur = useCallback(() => {
        clearSearchString();
        setIsFocused(false);
        if (onBlur) {
            onBlur();
        }
    }, [onBlur, clearSearchString]);

    return {
        isFocused,
        handleOnFocus,
        handleOnBlur,
        inputRef,
        blur
    };
}

export function useSelectLogic(
    clearSearchString: () => void,
    controlledSelectedItem: ItemType,
    blur: () => void,
    defaultValue: ItemType,
    onSelect?: (item: ItemType) => void
) {
    const [uncontrolledSelectedItem, setUncontrolledSelectedItem] = useState(defaultValue);
    const isSelectedItemControlled = controlledSelectedItem !== undefined;
    const handleSelect = useCallback((item) => {
        const currentItem = item !== undefined ? item : null;
        if (!isSelectedItemControlled) {
            setUncontrolledSelectedItem(currentItem);
        }
        blur();
        clearSearchString();
        if (onSelect) {
            onSelect(currentItem);
        }
    }, [blur, clearSearchString, isSelectedItemControlled, onSelect]);
    const selectedItem = isSelectedItemControlled ? controlledSelectedItem : uncontrolledSelectedItem;
    return {handleSelect, selectedItem};
}

export function useDisplayValueLogic(
    isFocused: boolean,
    selectedItem: ItemType,
    searchString?: string,
    itemToString?: (item: ItemType) => string): DisplayValueLogicReturnType {
    const usedItemToString = itemToString ? itemToString : defaultItemToString;
    const displayValue = !isFocused && selectedItem ? usedItemToString(selectedItem) : searchString;
    return {
        itemToString: usedItemToString,
        displayValue
    };
}

export function useEqualityLogic(itemToId?: (item: ItemType) => ItemIdReturnType): EqualityLogicReturnType {
    return {
        itemToId: itemToId ? itemToId : defaultItemToId
    };
}

function defaultItemToId(item: ItemType): ItemIdReturnType {
    if (typeof item === 'string') {
        return null;
    }
    return item && item.id ? item.id : null;
}

function defaultItemToString(item: ItemType): string {
    if (typeof item === 'string') {
        return '';
    }
    return item && item.name ? item.name : '';
}
