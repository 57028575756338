import {defineMessages} from "react-intl";


export default defineMessages({
    noAccessTitle: {
        id: 'approveAuthorizationPage.noAccessTitle'
    },
    noAccessDescription: {
        id: 'approveAuthorizationPage.noAccessDescription'
    },
    contactAdministrator: {
        id: 'approveAuthorizationPage.contactAdministrator'
    },
    title: {
        id: 'approveAuthorizationPage.title'
    },
    manageJobPositionsTitle: {
        id: 'approveAuthorizationPage.manageJobPositionsTitle'
    },
    manageJobPositionsDescription: {
        id: 'approveAuthorizationPage.manageJobPositionsDescription'
    },
    requestAssessmentsTitle: {
        id: 'approveAuthorizationPage.requestAssessmentsTitle'
    },
    requestAssessmentsDescription: {
        id: 'approveAuthorizationPage.requestAssessmentsDescription'
    },
    viewJobPositionsTitle: {
        id: 'approveAuthorizationPage.viewJobPositionsTitle'
    },
    viewJobPositionsDescription: {
        id: 'approveAuthorizationPage.viewJobPositionsDescription'
    },
    viewCandidatesTitle: {
        id: 'approveAuthorizationPage.viewCandidatesTitle'
    },
    viewCandidatesDescription: {
        id: 'approveAuthorizationPage.viewCandidatesDescription'
    },
    organization: {
        id: 'approveAuthorizationPage.organization'
    },
    organizationPlaceholder: {
        id: 'approveOrganizationPage.organizationPlaceholder'
    },
    confirmAccess: {
        id: 'approveAuthorizationPage.confirmAccess'
    }
});
