import React, {ReactNode} from "react";
import {AuthenticatedUserContext, useCreateAuthenticatedUserContext} from "./AuthenticatedUserContext";


interface WithAuthenticatedUserProps {
    children: ReactNode;
}

export function WithAuthenticatedUser({children}: WithAuthenticatedUserProps) {
    const {authenticatedUser, setAuthenticatedUser} = useCreateAuthenticatedUserContext();
    return (
        <AuthenticatedUserContext.Provider value={{authenticatedUser, setAuthenticatedUser}}>
            {children}
        </AuthenticatedUserContext.Provider>
    );
}
