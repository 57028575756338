import styled, {css} from 'styled-components';
import React, {ReactNode} from 'react';
import selectedItemIcon from './assets/selectedItem.svg';
import {fontSizeTokens} from "../../tokens/fontSize";
import {greyColorTokens} from "../../tokens/color";
import {lineHeightTokens} from "../../tokens/lineHeight";
import {ItemType} from "./Dropdown/types";
import {GetItemPropsOptions} from "downshift";
import {Organization} from "../../../models/Organization";
import {FormattedMessage} from "react-intl";
import messages from "./messages";
import {Caption} from "../../primitives/textStyles/Captions";

interface DropdownMenuItemProps {
    isSelected?: boolean;
    isHighlighted?: boolean;
    isDisabled?: boolean;
    children?: ReactNode
}

export const DropdownMenuItem = styled.div<DropdownMenuItemProps>`
    padding: 0 12px;
    height: 40px;
    font-family: 'Euclid Circular A', sans-serif;
    font-size: ${fontSizeTokens.smaller};
    line-height: ${lineHeightTokens.small};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${({isHighlighted}) => isHighlighted ? greyColorTokens.grey900(0.7) : greyColorTokens.white000()};
    cursor: pointer;
    color: ${greyColorTokens.grey000()};
    ${({isDisabled}) => isDisabled ? (css`
      opacity: 0.7;
    `) : ''}
`;


export const SelectedDropdownMenuItem = styled.div`
  height: 24px;
  width: 24px;
  background: url(${selectedItemIcon}) center center no-repeat;
`;

export const NoDropdownMenuItems = styled(DropdownMenuItem)`
    justify-content: center;
    &:hover {
      background-color: ${greyColorTokens.white000()};
    }
`;

interface DefaultDropdownMenuItemProps {
    item: ItemType;
    index: number;
    getItemProps: (props: GetItemPropsOptions<any>) => any;
    isSelected: boolean;
    isHighlighted: boolean;
    itemToString: (item: ItemType) => string;
    isDisabled?: boolean;
}

export function DefaultDropdownMenuItem({item, index, getItemProps, isSelected, isHighlighted, itemToString, isDisabled}: DefaultDropdownMenuItemProps) {
    return (
        <DropdownMenuItem
            {...getItemProps({
                item,
                index
            })}
            isSelected={isSelected}
            isHighlighted={isHighlighted}
            isDisabled={isDisabled}
        >
            <div>
                {item ? itemToString(item) : ''}
            </div>
            {isSelected ? (
                <SelectedDropdownMenuItem/>
            ) : null}
        </DropdownMenuItem>
    );
}

interface OrganizationMenuItemProps extends DefaultDropdownMenuItemProps{
    item: Organization;
}

export function OrganizationMenuItem({item, index, getItemProps, isSelected, isHighlighted, itemToString}: OrganizationMenuItemProps) {
    return (
        <OrganizationItem
            {...getItemProps({
                item,
                index
            })}
            isSelected={isSelected}
            isHighlighted={isHighlighted}
            isDisabled={!item.userIsAllowedToConfigureIntegration}
        >
            <div>
                {item ? itemToString(item) : ''}
            </div>
            {isSelected ? (
                <SelectedDropdownMenuItem/>
            ) : null}
            {!item.userIsAllowedToConfigureIntegration ? (
                <Caption color={greyColorTokens.grey600()}>
                    <FormattedMessage {...messages.noAccess}/>
                </Caption>
            ) : null}
        </OrganizationItem>
    );
}

export const OrganizationItem = styled(DropdownMenuItem)`
    ${({isDisabled}) => isDisabled ? (css`
      color: ${greyColorTokens.grey600()};
      pointer-events: none;
      background-color: ${greyColorTokens.white000()};
    `) : ''}
`;
